<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content>
    <div class="flex flex-col items-stretch justify-center gap-2">
        <div *ngIf="selectedCustomerRole >= 0">
            <div class="mat-subtitle-2">
                {{ 'customer.role' | translate }}
            </div>
            <sz-select
                [(ngModel)]="selectedCustomerRole"
                [options]="customerRoles"
            />
        </div>
        <div>
            <div class="mat-subtitle-2">
                {{ 'common.filter' | translate }}
            </div>
            <sz-input [(ngModel)]="filterTerm" />
        </div>
        <div class="h-72 overflow-auto">
            <mat-selection-list
                [multiple]="false"
                (selectionChange)="selectedCustomerId = $event.options[0].value"
            >
                <mat-list-option
                    *ngFor="
                        let customer of filteredCustomerList
                            | search: filterTerm : ['name']
                    "
                    [value]="customer.id"
                    [selected]="selectedCustomerId === customer.id"
                    class="!h-16"
                >
                    <div class="flex flex-row items-center gap-3">
                        <img
                            class="h-14 w-14 object-contain"
                            [src]="getImageUrl(customer) | secure | async"
                            alt=""
                        />
                        <span>{{ customer.name }}</span>
                    </div>
                </mat-list-option>
            </mat-selection-list>
        </div>
    </div>
    <sz-dialog-button-bar
        [primaryDisabled]="!selectedCustomerId"
        (primaryClick)="dialogRef.close(selectedCustomer)"
        (secondaryClick)="dialogRef.close()"
    />
</div>
