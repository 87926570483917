import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';
import { ensureLoaded } from 'src/app/services/api/cached-api';
import { CustomerRole, customerRoleArray } from 'src/app/services/constants';
import { getCustomerId, getCustomerRole } from 'src/app/shared/common';
import { Organization } from 'src/app/shared/models/customer';
import { environment } from 'src/environments/environment';

const customerRoleTerms = [
    'customer.role.super',
    'customer.role.administrator',
    'customer.role.oem',
    'customer.role.dealer',
    'customer.role.client',
];

@Component({
    templateUrl: './select-organization-dialog.component.html',
})
export class SelectOrganizationDialog implements OnInit {
    selectedCustomerId?: number;
    selectedCustomerRole?: CustomerRole;
    filterTerm?: string;
    customerRoles = [];
    customerList: Organization[] = [];

    get customerId() {
        return getCustomerId();
    }

    get customerRole() {
        return getCustomerRole();
    }

    get filteredCustomerList() {
        return this.customerList.filter(
            (customer) => customer.role == this.selectedCustomerRole,
        );
    }

    get selectedCustomer(): Organization {
        return this.customerList.find((c) => c.id == this.selectedCustomerId);
    }

    get onlyRoles(): CustomerRole[] {
        return this.data.onlyRoles ?? customerRoleArray;
    }

    constructor(
        private api: ApiService,
        public dialogRef: MatDialogRef<SelectOrganizationDialog>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title?: string;
            customerId?: number;
            onlyRoles?: CustomerRole[];
            organizations?: Organization[];
        },
    ) {
        this.data ??= {};
        this.data.title ??= 'organization';
        this.selectedCustomerId = this.data.customerId ?? this.customerId;
        for (
            let role = this.customerRole;
            role <= CustomerRole.Client;
            role += 1
        ) {
            if (this.onlyRoles.includes(role)) {
                this.customerRoles.push({
                    name: customerRoleTerms[role],
                    value: role,
                });
            }
        }
    }

    async ngOnInit() {
        await ensureLoaded([this.api.organizations]);
        this.customerList =
            this.data?.organizations ??
            this.api.organizations.current({ sort: 'name' });
        if (
            this.selectedCustomer &&
            this.onlyRoles.includes(this.selectedCustomer.role)
        ) {
            this.selectedCustomerRole = this.selectedCustomer.role;
        } else {
            this.selectedCustomerId = null;
            this.selectedCustomerRole = this.customerRoles[0].value;
        }
    }

    getImageUrl(customer) {
        return (
            environment.apiUrl + '/api/v2/customers/' + customer.id + '/logo'
        );
    }
}
